import { useCallback, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

import ListMenu from './components/ListMenu/ListMenu'
import styles from './Header.module.css'
import ButtonHamburger from './components/ButtonHamburger/ButtonHamburger'
import MobileMenu from './components/MobileMenu/MobileMenu'
import { Logo } from '../../../../utils/icons'

const Header = () => {
  const location = useLocation()

  const [isScrolled, setScrolled] = useState(location.pathname !== '/')
  const [isActiveMenu, setIsActiveMenu] = useState(false)

  const toggleActivateMenu = useCallback(() => {
    setIsActiveMenu(oldState => !oldState)
  }, [])

  useEffect(() => {
    const handleCheckScroll = () => setScrolled(!!window.scrollY)

    window.addEventListener('scroll', handleCheckScroll)
  }, [])

  return (
    <>
      <header
        className={`${styles.header} ${isScrolled || location.pathname !== '/' || isActiveMenu ? styles.scrolled : ''}`}
      >
        <div className="container">
          <div className="d-flex justify-content-between align-items-center gap-3">
            <Link to="/">
              <Logo />
            </Link>
            {location.pathname === '/' && (
              <>
                <ListMenu innerClassName={styles.desktopItems} />
                <ButtonHamburger
                  isActive={isActiveMenu}
                  onClickButton={toggleActivateMenu}
                />
              </>
            )}
          </div>
        </div>
      </header>
      <MobileMenu onHideMenu={toggleActivateMenu} isOpened={isActiveMenu} />
    </>
  )
}

export default Header
