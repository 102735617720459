import { LANDING_PATHS } from '../../utils/constants'
import FAQ from './components/FAQ/FAQ'
import Features from './components/Features/Features'
import FindGame from './components/FindGame/FindGame'
import Main from './components/Main/Main'
import NearestEvents from './components/NearestEvents/NearestEvents'
import Section from './components/Section/Section'

const ITEMS = [
  { path: LANDING_PATHS.HOME, Component: Main },
  { path: LANDING_PATHS.FEATURES, Component: Features },
  { path: LANDING_PATHS.NEAREST_EVENTS, Component: NearestEvents },
  { path: LANDING_PATHS.FAQ, Component: FAQ },
  {
    path: LANDING_PATHS.FIND_GAME,
    Component: FindGame,
  },
]

const Home = () => {
  return (
    <>
      {ITEMS.map(({ path, Component }) => (
        <Section key={path} path={path}>
          <Component />
        </Section>
      ))}
    </>
  )
}

export default Home
