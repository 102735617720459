import RedirectPage from '../components/RedirectPage'
import { REDIRECT_TYPE } from '../utils/constants'

const TournamentRedirect = () => {
  return (
    <RedirectPage
      documentTitle="Redirecting to Tournament"
      text="Redirecting to the tournament...."
      type={REDIRECT_TYPE.TOURNAMENT}
    />
  )
}

export default TournamentRedirect
