import RedirectPage from '../components/RedirectPage'
import { REDIRECT_TYPE } from '../utils/constants'

const GameRedirect = () => {
  return (
    <RedirectPage
      documentTitle="Redirecting to Game"
      text="Redirecting to the game...."
      type={REDIRECT_TYPE.GAME}
    />
  )
}

export default GameRedirect
