import RedirectPage from '../components/RedirectPage'
import { REDIRECT_TYPE } from '../utils/constants'

const PlayerRedirect = () => {
  return (
    <RedirectPage
      documentTitle="Redirecting to Player"
      text="Redirecting to the player...."
      type={REDIRECT_TYPE.PLAYER}
    />
  )
}

export default PlayerRedirect
