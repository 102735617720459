import React, { useEffect, useState } from 'react'

import styles from './ZendeskWidget.module.css'

const ZendeskWidget = () => {
  const [isWidgetLoaded, setIsWidgetLoaded] = useState(false)

  useEffect(() => {
    const script = document.createElement('script')
    script.id = 'ze-snippet'
    script.src =
      'https://static.zdassets.com/ekr/snippet.js?key=e76deb90-f840-48f1-9a58-1bb2469f2875'
    script.async = true
    document.body.appendChild(script)

    window.zESettings = {
      webWidget: {
        onReady: () => {
          setIsWidgetLoaded(true)
        },
      },
    }

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  useEffect(() => {
    if (isWidgetLoaded && window.zE) {
      window.zE('webWidget', 'open')
    }
  }, [isWidgetLoaded])

  const openZendeskWidget = () => {
    if (window.zE) {
      window.zE('webWidget', 'open')
    }
  }

  return (
    <div className={`container ${styles.container}`}>
      <h1>Zendesk Widget Demo</h1>
      <p>
        {isWidgetLoaded
          ? 'Zendesk widget is loaded and opened automatically.'
          : 'Zendesk widget is loading...'}
      </p>
      <button onClick={openZendeskWidget}>Open Zendesk Widget</button>
    </div>
  )
}

export default ZendeskWidget
