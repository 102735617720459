import React from 'react'

import styles from './ChooseVariety.module.css'
import {
  GameAmericano,
  GameFun,
  GameLeague,
  GameMexicano,
} from '../../../../../../utils/icons'
import GameType from './components/GameType/GameType'
import BlockBackground from '../../../../../../components/BlockBackground/BlockBackground'

const GAME_TYPES = [
  {
    Icon: GameFun,
    title: 'Fun Game',
    text: "The objective is to hit the ball over the net and into the opponent's court, aiming to make it bounce twice before they can return it.",
    color: '#4332E0',
  },
  {
    Icon: GameMexicano,
    title: 'Mexicano',
    text: "The objective is to hit the ball over the net and into the opponent's court, aiming to make it bounce twice before they can return it. ",
    color: '#FF701F',
  },
  {
    Icon: GameAmericano,
    title: 'Americano',
    text: 'Rather than playing on fixed teams, Americano players will change partners after every round.',
    color: '#00BC6D',
  },
  {
    Icon: GameLeague,
    title: 'League',
    text: 'The playoffs will start for the six couples that will finish number one in the groups and the best two couples that finish in second place.',
    color: '#E59700',
  },
]

const ChooseVariety = () => {
  return (
    <div className={styles.variety}>
      <BlockBackground />
      <div className={`${styles.varietyContent} container`}>
        <h3>Choose from variety of games types</h3>
        <div className={styles.varietyItems}>
          {GAME_TYPES.map(props => (
            <GameType key={props.title} {...props} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default ChooseVariety
