import {
  Home,
  GameRedirect,
  TournamentRedirect,
  PlayerRedirect,
  TermsAndConditions,
  PrivacyPolicy,
  ZendeskWidget,
} from './pages'

export const PUBLIC_ROUTES = [
  {
    path: '/',
    Component: Home,
  },
  { path: '/redirects/game/:id', Component: GameRedirect },
  {
    path: '/redirects/tournament/:id',
    Component: TournamentRedirect,
  },
  { path: '/redirects/player/:id', Component: PlayerRedirect },
  { path: '/terms-and-conditions', Component: TermsAndConditions },
  { path: '/privacy-policy', Component: PrivacyPolicy },
  { path: '/support', Component: ZendeskWidget },
]
