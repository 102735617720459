import React from 'react'

import styles from './TextLayout.module.css'

const TextLayout = ({ text, title }) => {
  return (
    <div className={styles.textLayout}>
      <div className="container">
        <div className={styles.content}>
          <h3>{title}</h3>
          <div className={styles.text}>{text}</div>
        </div>
      </div>
    </div>
  )
}

export default TextLayout
