import { useLocation } from 'react-router-dom'
import Footer from './components/Footer/Footer'
import Header from './components/Header/Header'

import styles from './Layout.module.css'
import BlockBackground from '../BlockBackground/BlockBackground'

const Layout = ({ children }) => {
  const location = useLocation()

  return (
    <main className={styles.landing}>
      <Header />
      {location.pathname !== '/' ? (
        <div className={styles.content}>
          <BlockBackground />
          {children}
        </div>
      ) : (
        children
      )}
      <Footer />
    </main>
  )
}

export default Layout
