import React from 'react'
import TextLayout from '../components/TextLayout/TextLayout'

const TERMS_AND_CONDITIONS = (
  <ol>
    <li>
      <strong>General Information</strong>
      <p>
        This Terms & Conditions document governs the use of the Fun Padel app,
        provided by PE Oleg Rudnicky, located at Panikahy str. 101, Kiev. For
        support inquiries, please contact us via email at{' '}
        <a href="mailto:support@funpadel.net">support@funpadel.net</a> or call{' '}
        <a href="tel:+380934310108">+380934310108</a>.
      </p>
    </li>
    <li>
      <strong>App Purpose</strong>
      <p>
        Fun Padel allows users to create and attend Padel tournaments. The app
        is free of charge and accessible to all users interested in Padel.
      </p>
    </li>
    <li>
      <strong>User Obligations</strong>
      <p>
        There are no specific rules or obligations for using Fun Padel. Users
        are free to create and participate in tournaments at their own
        discretion.
      </p>
    </li>
    <li>
      <strong>Payment and Refund Policy</strong>
      <p>
        The Fun Padel app is completely free to use. As a result, no refunds or
        cancellations are applicable.
      </p>
    </li>
    <li>
      <strong>Intellectual Property and Content</strong>
      <p>
        There are no specific guidelines regarding user-generated content.
        However, all users must respect the rights of others when using the app.
      </p>
    </li>
    <li>
      <strong>Liability Limitations</strong>
      <p>
        PE Oleg Rudnicky is not responsible for any misbehavior of the app or
        for any issues that arise from its usage. All cases should be resolved
        by contacting our support team at{' '}
        <a href="mailto:support@funpadel.net">support@funpadel.net</a>.
      </p>
    </li>
    <li>
      <strong>Termination of Use</strong>
      <p>
        We reserve the right to terminate or suspend user accounts in cases of
        misconduct or misuse of the app at our discretion.
      </p>
    </li>
    <li>
      <strong>Third-Party Services</strong>
      <p>Fun Padel does not integrate any third-party services.</p>
    </li>
    <li>
      <strong>Changes to Terms & Conditions</strong>
      <p>
        We reserve the right to update these Terms & Conditions at any time.
        Users will be notified of any significant changes through the app or by
        email.
      </p>
    </li>
  </ol>
)

const TermsAndConditions = () => {
  return <TextLayout title="Terms & Conditions" text={TERMS_AND_CONDITIONS} />
}

export default TermsAndConditions
