import React from 'react'
import TextLayout from '../components/TextLayout/TextLayout'

const PRIVACY_POLICY = (
  <ol>
    <li>
      <strong>Data Collection</strong>{' '}
      <p>Fun Padel collects the following personal information from users:</p>{' '}
      <ul>
        <li>
          <p>Email address</p>
        </li>
        <li>
          <p>User profile image</p>
        </li>
        <li>
          <p>City location</p>
        </li>
      </ul>
      <p>
        This data is collected to enhance the user experience within the app.
      </p>
    </li>
    <li>
      <strong>Data Usage</strong> <p>The data collected is used to:</p>{' '}
      <ul>
        <li>
          <p>Populate user profiles within the app.</p>
        </li>
        <li>
          <p>
            Suggest relevant Padel tournaments happening in the user&apos;s
            area.
          </p>
        </li>
      </ul>
    </li>
    <li>
      <strong>Data Sharing</strong>
      <p>Fun Padel does not share any user data with third-party services.</p>
    </li>
    <li>
      <strong>Data Storage</strong>
      <p>
        All collected data is stored on our servers hosted in AWS cloud storage,
        which is fully protected by Amazon&apos;s security measures to ensure
        data safety.
      </p>
    </li>
    <li>
      <strong>User Rights</strong>
      <p>Users have the right to:</p>
      <ul>
        <li>
          <p>Request access to their personal data.</p>
        </li>
        <li>
          <p>Request deletion of their personal data.</p>
        </li>
      </ul>
      <p>
        To exercise these rights, users can contact our support team at
        <a href="mailto:support@funpadel.net"> support@funpadel.net</a>.
      </p>
    </li>
    <li>
      <strong>Changes to this Privacy Policy</strong>
      <p>
        Fun Padel reserves the right to update this Privacy Policy at any time.
        Users will be notified of significant changes via the app or email.
      </p>
    </li>
  </ol>
)

const PrivacyPolicy = () => {
  return <TextLayout title="Privacy Policy" text={PRIVACY_POLICY} />
}

export default PrivacyPolicy
