import { Navigate, Route, Routes } from 'react-router-dom'

import { PUBLIC_ROUTES } from './routes'
import Layout from './components/Layout/Layout'
import useScrollToTop from './hooks/useScrollToTop'

import './App.css'

const App = () => {
  useScrollToTop()

  return (
    <Layout>
      <Routes>
        {PUBLIC_ROUTES.map(({ path, Component }) => (
          <Route key={path} path={path} element={<Component />} />
        ))}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Layout>
  )
}

export default App
