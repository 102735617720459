import React, { useEffect, useState } from 'react'
import { APP_STORE_LINKS, REDIRECT_LINKS } from '../utils/constants'
import { useParams, useSearchParams } from 'react-router-dom'

const getPlatform = () => {
  const userAgent = navigator.userAgent || window.opera
  if (/android/i.test(userAgent)) return 'android'
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) return 'ios'
  return 'default'
}

const RedirectPage = ({ documentTitle, text, type }) => {
  const { id } = useParams()
  const [searchParams] = useSearchParams()
  const privateKey = searchParams.get('privateKey')
  const [redirectStatus, setRedirectStatus] = useState('initial')

  useEffect(() => {
    if (!id) return

    const deepLink =
      REDIRECT_LINKS[type](id) + (privateKey ? `?privateKey=${privateKey}` : '')
    const platform = getPlatform()

    const handleRedirect = () => {
      setRedirectStatus('attempting')

      // Attempt to open the deep link
      window.location.href = deepLink

      // Set a timeout to check if we're still on the same page
      setTimeout(() => {
        if (document.hidden) {
          // If the document is hidden, the app likely opened
          setRedirectStatus('success')
        } else {
          // If we're still here, the app probably isn't installed
          setRedirectStatus('failed')
          if (platform === 'ios') {
            window.location.href = APP_STORE_LINKS.IOS
          } else if (platform === 'android') {
            window.location.href = APP_STORE_LINKS.ANDROID
          }
        }
      }, 2000) // Adjust this timeout as needed
    }

    handleRedirect()
  }, [id, type, privateKey])

  useEffect(() => {
    document.title = documentTitle
  }, [documentTitle])

  return (
    <div className="container">
      <h3 style={{ textAlign: 'center', paddingTop: '64px' }}>
        {redirectStatus === 'attempting'
          ? text
          : redirectStatus === 'failed'
            ? 'Redirecting to app store...'
            : 'Opening app...'}
      </h3>
    </div>
  )
}

export default RedirectPage
